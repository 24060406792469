import { MDText } from 'i18n-react';

import { VRM_CHECK_CODES } from 'Utilities/vrm';

import LocalTexts from '../../../fastlane/homeVRM/homeVRMErrors/HomeVRMErrorsTexts.json';

const LocalT = new MDText(LocalTexts);

export const vrmErrorTexts = (errorMsg?: string, vrm?: string) => {
  let title;
  let detail;

  switch (errorMsg) {
    case VRM_CHECK_CODES.EXCEEDED: {
      title = LocalT.translate('exceeded.title');
      detail = LocalT.translate('exceeded.detail');

      break;
    }
    case VRM_CHECK_CODES.INVALID_VRM: {
      title = LocalT.translate('invalid_vrm.title', { vrm });
      detail = LocalT.translate('invalid_vrm.detail');

      break;
    }
    case VRM_CHECK_CODES.TOO_MANY_REQUESTS: {
      title = LocalT.translate('too_many_requests.title');
      detail = LocalT.translate('too_many_requests.detail');

      break;
    }
    case VRM_CHECK_CODES.VEHICLE_GENERATION_FAILURE: {
      title = LocalT.translate('vehicle_generation_failure.title');
      detail = LocalT.translate('vehicle_generation_failure.detail');

      break;
    }
    case VRM_CHECK_CODES.VEHICLE_LOOKUP_FAILED: {
      title = LocalT.translate('vehicle_lookup_failed.title');
      detail = LocalT.translate('vehicle_lookup_failed.detail');

      break;
    }
    default: {
      title = LocalT.translate('default.title');
      detail = LocalT.translate('default.detail');

      break;
    }
  }

  return { detail, title } as Record<string, string>;
};
