import { useEffect, useRef } from 'react';

import { useStopAnimationFallback } from 'Utilities/animation';

import styles from './HeroFrameAnimation.scss';

const ANIMATION_DURATION = 700;

type HeroFrameAnimationProps = {
  isAnimating: boolean;
  setIsAnimating: (isAnimating: boolean) => void;
}

export const HeroFrameAnimation = ({ isAnimating, setIsAnimating }: HeroFrameAnimationProps) => {
  const elementRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const element = elementRef?.current;
    const stopProgress = () => setIsAnimating(false);

    if (element) {
      element.addEventListener('animationend', stopProgress);
      element.addEventListener('animationcancel', stopProgress);

      return () => {
        element.removeEventListener('animationend', stopProgress);
        element.removeEventListener('animationcancel', stopProgress);
      };
    }
  }, [elementRef, setIsAnimating]);

  useStopAnimationFallback(isAnimating, () => setIsAnimating(false), ANIMATION_DURATION);

  return <div ref={elementRef} className={styles.heroFrameAnimation} />;
};
