import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { INFOBOX_VARIANT } from '@motorway/mw-highway-code/enums';
import { toast } from '@motorway/mw-highway-code/pre-alpha';

import { VehicleLookupContext } from 'Context/vehicleLookup';

import { VALUATIONS_GA_TAGS } from 'Utilities/analytics';
import { VehicleLookupState } from 'Utilities/vehicles/@types';

import { mileageRegex, PATHS } from '../../../homepage/Components/Hero/Hero.constants';
import { vrmErrorTexts } from '../Helpers/HomeVRM.helper';

type UseHeroRouteChangeHelpersProps = {
  vehicle: VehicleLookupState;
};

export const useHeroRouteChangeHelpers = ({ vehicle }: UseHeroRouteChangeHelpersProps) => {
  const history = useHistory();
  const { vehicleLookupActions } = useContext(VehicleLookupContext);

  const [showMileage, setShowMileage] = useState(history.location.pathname.endsWith(PATHS.mileage));

  const hasError = vehicle?.resultError;

  const setMileageUrl = useCallback((value: boolean) => {
    let newPathname;
    const { pathname } = history.location;

    if (value) {
      newPathname = pathname === PATHS.home ? PATHS.mileage : `${pathname}${PATHS.mileage}`;
    } else {
      newPathname = pathname.replace(mileageRegex, '');
    }

    history.push(
      history.createHref({
        ...history.location,
        pathname: newPathname,
      }),
    );
  }, [history]);

  const onNotMyCarClick = useCallback((vrm: string) => {
    VALUATIONS_GA_TAGS.HERO_MILEAGE_NOT_MY_CAR_CLICKED();
    VALUATIONS_GA_TAGS.HERO_MILEAGE_NOT_MY_CAR_CLICKED_SNOWPLOW(vrm);
    setMileageUrl?.(false);
    vehicleLookupActions.remove();
  }, [setMileageUrl, vehicleLookupActions]);

  useEffect(() => {
    // Fail safe to ensure you can't end up on mileage without a vehicle
    if (!vehicle?.id) {
      setShowMileage(false);
      history.replace({
        ...history.location,
        pathname: history.location.pathname.replace(mileageRegex, '') || PATHS.home,
      });
    }
    const unlisten = history.listen(({ pathname }) => {
      // If someone clicks `Not my car` it resets the store,
      // so need to make sure if no vehicle, no mileage page!
      setShowMileage(vehicle?.id ? pathname.endsWith(PATHS.mileage) : false);

      // reset url to '/' if `Not my car` is clicked and then browser back is clicked
      if (pathname.endsWith(PATHS.mileage) && !vehicle?.id) {
        history.replace({
          ...history.location,
          pathname: history.location.pathname.replace(mileageRegex, '') || PATHS.home,
        });
      }
    });

    return () => unlisten();
  }, [history, vehicle?.id]);

  useEffect(() => {
    // Fail safe to ensure you can't end up on mileage without a vehicle
    if (!vehicle?.id) {
      setShowMileage(false);
    }
  }, [vehicle?.id]);

  const { detail, title: errorToastTitle } = vrmErrorTexts(vehicle?.resultError?.msg, vehicle?.resultError?.vrm);

  useEffect(() => {
    if (hasError) {
      VALUATIONS_GA_TAGS.VEHICLE_LOOKUP_FAILED(hasError);
      VALUATIONS_GA_TAGS.HERO_VEHICLE_LOOKUP_FAILED(hasError);
      toast({
        content: detail,
        heading: errorToastTitle,
        important: true,
        variant: INFOBOX_VARIANT.ERROR,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  return { onNotMyCarClick, setMileageUrl, showMileage };
};
