import { CSSProperties, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { VehicleLookupContext } from 'Context/vehicleLookup';

import { HERO_ANIMATION_STATES } from 'Utilities/animation';
import { usePrevious } from 'Utilities/hooks';

import { useBreakpoint } from '../../../../../components/misc/Breakpoints/Breakpoints';

type useHeroHelpersProps = {
  animateFromHomePage?: boolean;
  container?: React.RefObject<HTMLDivElement>;
};
export const useHeroHelpers = ({ animateFromHomePage = false, container }: useHeroHelpersProps) => {
  const breakpoints = useBreakpoint();
  const { breakpointTabletMobile } = breakpoints.minWidth;
  const history = useHistory();

  const { vehicleLookupState: vehicle } = useContext(VehicleLookupContext);

  const [minHeight, setMinHeight] = useState('auto');
  const [loading, setLoading] = useState(HERO_ANIMATION_STATES.none);
  const isVrmTransition = loading === HERO_ANIMATION_STATES.vrm as string;
  const isMileageTransition = loading === HERO_ANIMATION_STATES.mileage as string;

  const [isAnimatingVrmTransition, setIsAnimatingVrmTransition] = useState(false);
  const [isAnimatingMileageTransition, setIsAnimatingMileageTransition] = useState(false);
  const prevIsAnimatingMileageTransition = usePrevious(isAnimatingMileageTransition);

  const loadingHandler = useCallback((val: any) => setLoading(val), []);

  /**
    * IMPORTANT
    * If altering the height of `.contentWrapper` ensure you alter the min-heights to
    * prevent Google Pagespeed from penalising us for Cumlative Layout Shift
  */

  const cssVars = (loading && { '--lock-opacity-delay': '0.1s', '--vrm-input-opacity-delay': '0.1s' } as CSSProperties) || {};

  useEffect(() => {
    if (loading && breakpointTabletMobile) {
      window.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: 0,
      });
    }
  }, [loading, breakpointTabletMobile]);

  useEffect(() => {
    if (isMileageTransition && prevIsAnimatingMileageTransition && !isAnimatingMileageTransition) {
      history.push(`/${vehicle.vrm}`, { animate: true, animateFromNewHomePage: animateFromHomePage });
    }
  }, [
    animateFromHomePage,
    isMileageTransition,
    prevIsAnimatingMileageTransition,
    isAnimatingMileageTransition,
    vehicle?.vrm,
    history,
  ]);

  useEffect(() => {
    if (loading) {
      setMinHeight(`${container?.current?.getBoundingClientRect().height ?? 0}px`);
    }
    if (isVrmTransition) {
      setIsAnimatingVrmTransition(true);
    } else if (isMileageTransition) {
      setIsAnimatingMileageTransition(true);
    }
  }, [loading, isVrmTransition, isMileageTransition, container]);

  return {
    cssVars,
    isAnimatingMileageTransition,
    isAnimatingVrmTransition,
    isMileageTransition,
    loading,
    loadingHandler,
    minHeight,
    prevIsAnimatingMileageTransition,
    setIsAnimatingMileageTransition,
    setIsAnimatingVrmTransition,
  };
};
