import React, { useContext, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useLocation } from 'react-router';

import { MDText } from 'i18n-react';

import { Button } from '@motorway/mw-highway-code';
import { BUTTON_TYPE, SIZE, VARIANT } from '@motorway/mw-highway-code/enums';

import { VehicleLookupContext } from 'Context/vehicleLookup';

import { VALUATIONS_GA_TAGS } from 'Utilities/analytics';
import { HOMEPAGE_GA_TAGS } from 'Utilities/analytics/analyticsHomePage';
import { getMileage } from 'Utilities/mileage';

import { useHeroMileageInputHelpers } from '../../../../../fastlane/hooks/useHeroMileageInputHelpers';
import MileageInput from '../../../../../fastlane/mileageInput/MileageInput';
import MileageInputHigh from '../../../../../fastlane/mileageInput/MileageInputHigh';
import LocalTexts from '../../HeroTexts.json';

import styles from './HeroInputMileageWrapper.scss';

const LocalT = new MDText(LocalTexts);

const t = (key: string, args?: Record<string, string | React.ReactNode>) => `${LocalT.translate(key, args)}`;

const TEXTS = {
  confirmMileage: t('cta.confirmMileage'),
  continue: t('cta.continue'),
};

export type HeroMileageProps = {
  isCarValueTracker?: boolean;
  loadingHandler: (state: string) => void;
  onSubmit?: (mileage: number) => void;
  setShowMileage: (showMileage: boolean) => void;
  showMileage: boolean;
};

export const HeroInputMileageWrapper = ({
  isCarValueTracker, loadingHandler, onSubmit, setShowMileage, showMileage,
}: HeroMileageProps) => {
  const { vehicleLookupState: lookupVehicle } = useContext(VehicleLookupContext);
  const vehicleLookupMileage = getMileage({ vehicle: lookupVehicle });
  const vrm = lookupVehicle?.vrm ?? '';

  const { pathname } = useLocation();

  const isHomepage = pathname === '/mileage';

  const {
    confirmedMileage, confirmMileageHelpers, onFormSubmit,
  } = useHeroMileageInputHelpers({
    isCarValueTracker,
    loadingHandler,
    onSubmit,
    setShowMileage,
  });

  useEffect(() => {
    if (showMileage) {
      HOMEPAGE_GA_TAGS.HERO_MILEAGE_SHOWN(vrm, isHomepage);
      VALUATIONS_GA_TAGS.HERO_VEHICLE_LOOKUP_COMPLETE();
      VALUATIONS_GA_TAGS.VEHICLE_LOOKUP_COMPLETE(vrm);
    }
  }, [showMileage, vrm, isHomepage]);

  const ctaText = (vehicleLookupMileage ? TEXTS.confirmMileage : TEXTS.continue);

  return (
    <>
      <Form
        initialValues={{
          mileage: vehicleLookupMileage,
        }}
        onSubmit={({ mileage }) => {
          HOMEPAGE_GA_TAGS.HERO_MILEAGE_CTA_CLICKED(isHomepage);
          onFormSubmit({ mileage });
        }}
        render={({ handleSubmit, submitting, values }) => (
          <form
            noValidate
            className={styles.component}
            onSubmit={handleSubmit}
          >
            <MileageInput
              isHero
              isUseTHCInput
              validateOnInit
              titleClassName={styles.label}
            />
            <Button
              data-thc-button
              data-cy={'continueButton'}
              icon={'chevron'}
              label={ctaText}
              loading={submitting}
              onClick={() => {
                const mileage = values?.mileage;
                VALUATIONS_GA_TAGS.HERO_MILEAGE_BUTTON_CLICKED_SNOWPLOW({ mileage, vrm });
                if (!mileage) {
                  HOMEPAGE_GA_TAGS.HERO_MILEAGE_INPUT_EMPTY(isHomepage);
                }
              }}
              size={SIZE.LARGE}
              type={BUTTON_TYPE.SUBMIT}
              variant={VARIANT.PRIMARY}
            />
          </form>
        )}
      />
      {
        confirmedMileage
        && (<MileageInputHigh
          confirmMileage={confirmMileageHelpers()}
          modalClassName={styles.highMileageModalHeadings}
          {...confirmedMileage}
        />)
      }
    </>
  );
};
