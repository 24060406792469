import React, { useContext } from 'react';
import cx from 'classnames';

import { VehicleLookupContext } from 'Context/vehicleLookup';

import { VehicleLookupState } from 'Utilities/vehicles/@types';

import { HeroInputMileageWrapper } from './SubComponents/HeroInputMileageWrapper/HeroInputMileageWrapper';
import { HeroInputVrm as InputVRM } from './SubComponents/HeroInputVrm/HeroInputVrm';

import styles from './HeroInput.scss';

type HeroInputProps = {
  className?: string | null;
  inProgress: boolean;
  loadingHandler?: ((value: unknown) => void);
  setShowMileage?: ((value: boolean) => void);
  showMileage: boolean;
  vehicle: VehicleLookupState | null;
};

export const HeroInput = ({
  className,
  inProgress = false,
  loadingHandler = () => {},
  setShowMileage = () => {},
  showMileage = false,
  vehicle: vehicleProp = null,
}:HeroInputProps) => {
  const { vehicleLookupState: lookupVehicle } = useContext(VehicleLookupContext);
  const vehicle = vehicleProp || lookupVehicle;
  return (
    <section className={cx(styles.container, className, {
      [styles.fadeOut]: inProgress,
    })}>
      {
        showMileage ? (
          <HeroInputMileageWrapper
            {...{ loadingHandler, setShowMileage, showMileage }}
          />
        ) : (
          <InputVRM
            vrm={vehicle?.vrm ?? vehicle?.resultError?.vrm ?? ''}
            {...{ loadingHandler, setShowMileage }}

          />
        )
      }

    </section>
  );
};
