import { LANDING_PAGE_CATEGORY, LANDING_PAGE_EVENTS, LANDING_PAGE_SECTIONS_NAMES } from 'Utilities/analytics/analyticsLandingPage';

export type RelatedGuidesContentType = {
  description: string;
  thumbnail: string | { alt: string, url: string };
  title: string;
  url: string;
}[];

type FilterComponentsType = {
  acf: {
    [key: string]: string;
  };
  components: {
    description: string;
    thumbnail: string;
    title: string;
    url: string;
  }[];
};

export const numWord = ['one', 'two', 'three', 'four', 'five', 'six'];

export const relatedGuidesNames = numWord.map((n) => ({
  description: `related_guide_description_${n}`,
  thumbnail: `related_guide_image_${n}`,
  title: `related_guide_title_${n}`,
  url: `related_guide_url_${n}`,
}));

export const filterComponents = ({
  acf,
  components,
}: FilterComponentsType) => components.filter((component) => acf[component.title]);

export const FAQImage = {
  imgSrc: 'https://blog.motorway.co.uk/wp-content/uploads/2022/11/Changes-2.png',
};

export const PAGE_SECTIONS = {
  DEALER_LOGOS: 'DEALER_LOGOS',
  FAQ: 'FAQ',
  HERO: 'HERO',
  HOW_IT_WORKS: 'HOW_IT_WORKS',
  RECENT_SALES: 'RECENT_SALES',
  RELATED_GUIDES: 'RELATED_GUIDES',
  TRUSTPILOT: 'TRUSTPILOT',
  USP: 'USP',
  VIDEO: 'VIDEO',
} as const;

const LANDINGPAGE_SECTION_EVENT_NAMES = {
  [PAGE_SECTIONS.DEALER_LOGOS]: LANDING_PAGE_SECTIONS_NAMES.DEALER_LOGOS,
  [PAGE_SECTIONS.FAQ]: LANDING_PAGE_SECTIONS_NAMES.FAQ,
  [PAGE_SECTIONS.HERO]: LANDING_PAGE_SECTIONS_NAMES.HERO,
  [PAGE_SECTIONS.HOW_IT_WORKS]: LANDING_PAGE_SECTIONS_NAMES.HOW_IT_WORKS,
  [PAGE_SECTIONS.RECENT_SALES]: LANDING_PAGE_SECTIONS_NAMES.RECENT_SALES,
  [PAGE_SECTIONS.RELATED_GUIDES]: LANDING_PAGE_SECTIONS_NAMES.RELATED_GUIDES,
  [PAGE_SECTIONS.TRUSTPILOT]: LANDING_PAGE_SECTIONS_NAMES.TRUSTPILOT_WIDGET,
  [PAGE_SECTIONS.USP]: LANDING_PAGE_SECTIONS_NAMES.USP,
  [PAGE_SECTIONS.VIDEO]: LANDING_PAGE_SECTIONS_NAMES.ADVERT,
} as const;

export const landingPageSectionLoadEvent = (sectionName: keyof typeof PAGE_SECTIONS) =>
  LANDING_PAGE_EVENTS.SECTION_LOAD({ name: LANDINGPAGE_SECTION_EVENT_NAMES[sectionName] });

export const howItWorksSectionAnalyticsConfig = {
  category: LANDING_PAGE_CATEGORY,
  name: LANDING_PAGE_SECTIONS_NAMES.HOW_IT_WORKS,
} as const;
